import { LanguageId, TaxonomyTermFieldsFragment, TermsDocument } from '../graphql/generated'

const vocabulary = [
  'visible_tags',
  'event_info_type',
  'tocco_event_category',
  'tocco_supply_area',
  'tocco_target_group',
  'tocco_event_qualification',
  'cbz_education',
  'cbz_region',
  'institution_sector',
] as const
export type TermVocabulary = (typeof vocabulary)[number]

export interface Term {
  id: string
  name: string
  parent: string
}

export type Terms = Record<TermVocabulary, Term[]>

const getTerms = async (
  query: any,
  vocabularies: TermVocabulary[],
  parents: string[],
  language: LanguageId,
): Promise<Terms> => {
  const { data } = await useAsyncData(`terms`, async () => {
    const response = await Promise.allSettled(
      vocabularies.map(async (vocabulary) => {
        const { data } = await query({
          query: TermsDocument,
          variables: {
            vocabulary,
            parentId: parents,
            language,
            ...(vocabulary === 'institution_sector' ? { limit: 50 } : {}),
          },
        })
        if (!data) return null
        return [
          vocabulary,
          (data.taxonomyTermQuery?.entities as TaxonomyTermFieldsFragment[])?.map((item) => {
            return {
              id: item.tid?.toString() || '',
              name: item.name || '',
              parent: (item.parent as any)?.[0].targetId?.toString(),
            }
          }) || [],
        ] as const
      }),
    )

    const sucessfulRequests = response.filter((res) => res.status === 'fulfilled') as {
      status: 'fulfilled'
      value: [TermVocabulary, Term[]]
    }[]
    const terms = sucessfulRequests
      .map((res) => res.value)
      .reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {} as Record<TermVocabulary, Term[]>)

    return terms
  })

  if (!data.value) return {} as Terms

  return data.value
}

export const useTerms = async (vocabularies: TermVocabulary[], parents: string[] = ['0']) => {
  const { languageCODE } = useLocale()
  const { clients } = useApollo()

  const terms = ref(await getTerms(clients?.default.query, vocabularies, parents, languageCODE.value as LanguageId))
  const active = reactive(
    vocabularies.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: [],
      }),
      {} as Record<TermVocabulary, string[]>,
    ),
  )

  return { terms, active } as const
}
